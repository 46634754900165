import { useRef } from "react";

interface BongAnimationProps {
	duration: number;
}

export const useBongAnimation = ({ duration }: BongAnimationProps) => {
	const progressRef = useRef<number>(0);
	const startTime = useRef<number>(0);
	const requestRef = useRef<number | null>(null);
	const ref = useRef<HTMLImageElement>(null);

	const start = () => {
		if (!ref.current) return;
		progressRef.current = 100;
		startTime.current = performance.now();

		const animate = (time: number) => {
			if (!requestRef.current) return;
			if (!ref.current) return;
			if (!startTime.current) return;
			const timeElapsed = time - startTime.current;
			const progressDelta = (timeElapsed / duration) * 100;
			progressRef.current = Math.max(0, 100 - progressDelta);
			ref.current.style.setProperty(
				"mask-image",
				`linear-gradient(to right, rgba(0,0,0,1) ${progressRef.current}%, rgba(0,0,0,0) ${
					progressRef.current + 20
				}%)`
			);
			if (progressRef.current > 0) {
				requestRef.current = requestAnimationFrame(animate);
			} else {
				ref.current.style.setProperty(
					"mask-image",
					"linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 20%)"
				);
				cancelAnimationFrame(requestRef.current);
				return;
			}
		};
		requestRef.current = requestAnimationFrame(animate);
	};

	return { ref, start };
};
