import CardCloud1 from "../assets/cardCloud1.webp";
import CardCloud2 from "../assets/cardCloud2.webp";
import CardCloud3 from "../assets/cardCloud3.webp";
import CardCloud4 from "../assets/cardCloud4.webp";

interface CardBackgroundProps {
	index: number;
}

const cardBackgroundUrls = [CardCloud1, CardCloud2, CardCloud3, CardCloud4];

const CardBackground: React.FC<CardBackgroundProps> = ({ index }) => {
	const url = cardBackgroundUrls[index % cardBackgroundUrls.length];
	console.log(url);
	return (
		<img style={{ width: "100%", filter: "drop-shadow(0px 0px 4px #fff)", }} src={url} alt="background" />
	);
};

export { CardBackground };
