import { useRef, useCallback, useMemo, useEffect } from "react";

export interface AnimationParameters {
	spawnRate: number;
	speed: number;
	angleOffset: number;
	curvature: number;
	opacity: number;
}

interface Disk {
	radius: number;
	color: string;
}

export const useCanvasAnimation = (
	duration: number,
	parametricFunction: (duration: number, timeElapsed: number) => AnimationParameters
) => {
	const ref = useRef<HTMLCanvasElement>(null);
	const requestRef = useRef<number>();
	const lastSpawnRef = useRef<number>(Date.now());
	const disksRef = useRef<Disk[]>([]);
	const gradientIndexRef = useRef<number>(0);
	const animationOn = useRef(false);
	const animationStartTime = useRef<number | null>(null);

	const gradientColors = useMemo(
		() => [
			"rgb(247, 253, 62)",
			"rgb(252, 110, 69)",
			"rgb(252, 69, 216)",
			"rgb(193, 69, 252)",
			"rgb(69, 86, 252)",
			"rgb(69, 225, 252)",
			"rgb(69, 252, 233)",
			"rgb(69, 252, 147)",
			"rgb(197, 252, 69)",
			"rgb(252, 227, 69)",
		],
		[]
	);

	const spawnDisk = useCallback(
		(radius: number) => {
			const color = gradientColors[gradientIndexRef.current % gradientColors.length];
			gradientIndexRef.current++;
			disksRef.current.push({ radius, color });
		},
		[gradientColors]
	);

	const updateDisks = useCallback((speed: number, maxRadius: number) => {
		const newDisks = [];
		for (const disk of disksRef.current) {
			disk.radius += speed;
			if (disk.radius < maxRadius) {
				newDisks.push(disk);
			}
		}
		disksRef.current = newDisks;
	}, []);

	const drawDisks = useCallback(
		(context: CanvasRenderingContext2D, x: number, y: number, angleOffset: number, curvature: number) => {
			context.clearRect(0, 0, context.canvas.width, context.canvas.height);
			const numPoints = 15; // Number of points in the star shape
			const angleIncrement = (2 * Math.PI) / numPoints; // Angle between points

			// Draw disks in reverse order to show the correct overlap
			for (const disk of disksRef.current) {
				// Loop through the number of points to draw the star shape
				for (let j = 0; j < numPoints; j++) {
					// Calculate angles for the two vertices
					const angle1 = angleIncrement * j + angleOffset;
					const angle2 = angle1 + angleIncrement;

					// Calculate points on the circumference of the disk
					const x1 = x + disk.radius * Math.cos(angle1);
					const y1 = y + disk.radius * Math.sin(angle1);
					const x2 = x + disk.radius * Math.cos(angle2);
					const y2 = y + disk.radius * Math.sin(angle2);

					// Calculate midpoint for the arc to create the inward curve
					const midAngle = (angle1 + angle2) / 2;
					const midRadius = disk.radius * curvature; // Adjust this value to change the curvature
					const midX = x + midRadius * Math.cos(midAngle);
					const midY = y + midRadius * Math.sin(midAngle);

					// Draw the arc from one point to the next
					context.beginPath();
					context.moveTo(x1, y1);
					context.quadraticCurveTo(midX, midY, x2, y2);
					context.strokeStyle = disk.color;
					context.lineWidth = 1; // Width of the star's edges
					context.stroke();
				}
			}
		},
		[]
	);

	const animate = useCallback(() => {
		const canvas = ref.current!;
		const context = canvas.getContext("2d")!;

		const currentTime = Date.now();
		const timeElapsed = animationStartTime.current ? currentTime - animationStartTime.current : 0;

		const { spawnRate, angleOffset, curvature, speed, opacity } = parametricFunction(duration, timeElapsed);
		const maxRadius = Math.min(canvas.width, canvas.height) / 2;

		// Update opacity
		context.globalAlpha = opacity;

		// Update and draw disks
		updateDisks(speed, maxRadius);
		drawDisks(context, canvas.width / 2, canvas.height / 2, angleOffset, curvature);

		if (timeElapsed >= duration) {
			animationOn.current = false;
		}
		if (currentTime - lastSpawnRef.current > spawnRate && animationOn.current) {
			spawnDisk(2);
			lastSpawnRef.current = currentTime;
		}
		if (!animationOn.current && disksRef.current.length === 0 && requestRef.current) {
			cancelAnimationFrame(requestRef.current);
			return;
		}
		requestRef.current = requestAnimationFrame(animate);
	}, [duration, parametricFunction, spawnDisk, updateDisks, drawDisks]);

	const start = useCallback(() => {
		if (animationOn.current) return;
		if (!ref.current) return;
		if (!ref.current.getContext("2d")) return;
		animationOn.current = true;
		animationStartTime.current = Date.now();
		requestRef.current = window.requestAnimationFrame(animate);
	}, [animate]);

	// Cleanup effect to cancel the animation frame if the component unmounts
	useEffect(() => {
		return () => {
			if (requestRef.current) {
				window.cancelAnimationFrame(requestRef.current);
			}
		};
	}, []);

	return { ref, start };
};
