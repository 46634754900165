import { useWebApp } from "@vkruglikov/react-telegram-web-app";

import { CardBackground } from "./cardBackground";

import Clickable from "./Clickable";

import { TaskData } from "../types/Task";
import { BybitReferralTask } from "../types/BybitTask";

import coinLogo from "../assets/coinLogo.webp";
import taskOpenButton from "../assets/linkOpen.webp";
import checkButton from "../assets/checkButton.webp";
import doneIcon from "../assets/taskDone.webp";
import hourglassIcon from "../assets/hourglassIcon.webp";

interface TaskCardProps {
	index: number;
	id: string;
	title: string;
	reward: number;
	data: TaskData;
	isChecked: boolean;
	isCompleted: boolean;
	uncompletedAnimation: boolean;
	uncompletedAnimationText: string;
	onCheck: (taskId: string) => Promise<void>;
	onVerify: () => void;
	earnAnimation: boolean;
}

const TaskCard: React.FC<TaskCardProps> = ({
	index,
	id,
	title,
	reward,
	data,
	isChecked,
	isCompleted,
	uncompletedAnimation,
	uncompletedAnimationText,
	onCheck,
	onVerify,
	earnAnimation,
}) => {
	const webApp = useWebApp();
	const handleOpenTask = () => {
		switch (data.type) {
			case "TELEGRAM_CHANNEL_SUBSCRIPTION": {
				webApp.openTelegramLink(data.inviteLink);
				break;
			}
			default: {
				console.error("Unknown task type");
			}
		}
	};

	const handleCheck = () => {
		onCheck(id).then(() => {
			handleOpenTask();
		});
	};

	return (
		<div className="card task">
			<div className="card-content">
				<div className="card-left" style={{ flex: 7 }} onClick={handleCheck}>
					<div className="card-info">
						<p className="task-title">{title}</p>
						{!isCompleted && (
							<div className={`task-reward${earnAnimation ? " earn-animation" : ""}`}>
								+ {reward}
								<img
									src={coinLogo}
									alt="logo"
									style={{
										width: "10%",
										filter: "drop-shadow(var(--quaternary-color) -0.5px -0.5px 0.01em) drop-shadow(var(--quaternary-shadow-color) 0.5px 0.5px 0.01em)",
									}}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="card-right" style={{ flex: 2, justifyContent: "center" }}>
					{isCompleted ? (
						<img className="icon-shadow" width={"70%"} src={doneIcon} alt="" />
					) : isChecked ? (
						<Clickable>
							<button style={{ background: "none", border: "none" }} onClick={onVerify}>
								<img className="icon-shadow" width={"100%"} src={checkButton} alt="" />
							</button>
						</Clickable>
					) : (
						<Clickable>
							<button style={{ background: "none", border: "none" }} onClick={handleCheck}>
								<img className="icon-shadow" width={"60%"} src={taskOpenButton} alt="" />
							</button>
						</Clickable>
					)}
					{uncompletedAnimation && (
						<div
							style={{ position: "absolute", right: "0", top: "30%", width: "300%" }}
							className="earn-animation"
						>
							<p style={{ fontSize: "1em" }} className="claim-ref-points-amount">
								{uncompletedAnimationText}
							</p>
						</div>
					)}
				</div>
			</div>
			<CardBackground index={index} />
		</div>
	);
};

interface BybitReferralTaskCardProps {
	index: number;
	task: BybitReferralTask | null;
	title: string;
	reward: number;
	onOpen: () => void;
	onClaim: () => void;
	earnAnimation: boolean;
}

const BybitReferralTaskCard: React.FC<BybitReferralTaskCardProps> = ({
	index,
	task,
	title,
	reward,
	onOpen,
	onClaim,
	earnAnimation,
}) => {
	console.log("Bybit task: ", task);
	const renderReward = task === null || task.status !== "CLAIMED";
	const canOpen = renderReward && !earnAnimation;
	return (
		<div className="card task">
			<div className="card-content">
				<div className="card-left" style={{ flex: 7 }} onClick={canOpen ? onOpen : undefined}>
					<div className="card-info">
						<p className="task-title">{title}</p>
						{renderReward && (
							<div className={`task-reward${earnAnimation ? " earn-animation" : ""}`}>
								+ {reward}
								<img
									src={coinLogo}
									alt="logo"
									style={{
										width: "10%",
										filter: "drop-shadow(var(--quaternary-color) -0.5px -0.5px 0.01em) drop-shadow(var(--quaternary-shadow-color) 0.5px 0.5px 0.01em)",
									}}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="card-right" style={{ flex: 2, justifyContent: "center" }}>
					{task === null ? (
						<Clickable>
							<button style={{ background: "none", border: "none" }} onClick={onOpen}>
								<img className="icon-shadow" width={"60%"} src={taskOpenButton} alt="" />
							</button>
						</Clickable>
					) : task.status === "CLAIMED" ? (
						<img className="icon-shadow" width={"70%"} src={doneIcon} alt="" />
					) : task.status === "APPROVED" ? (
						<Clickable>
							<button style={{ background: "none", border: "none" }} onClick={onClaim}>
								<img className="icon-shadow" width={"100%"} src={checkButton} alt="" />
							</button>
						</Clickable>
					) : (
						<button style={{ background: "none", border: "none" }}>
							<img className="icon-shadow" width={"72%"} src={hourglassIcon} alt="" />
						</button>
					)}
				</div>
			</div>
			<CardBackground index={index} />
		</div>
	);
};

export { TaskCard, BybitReferralTaskCard };
