import { useCallback, useRef } from "react";

interface ParticleProps {
	context: CanvasRenderingContext2D;
	image: HTMLImageElement;
}

function generateRandomNumber(min: number, max: number): number {
	return Math.random() * (max - min) + min;
}

class Particle {
	x: number;
	y: number;
	xVelocity: number;
	yVelocity: number;
	currentOpacity: number;
	radius: number;
	rotation: number;
	context: CanvasRenderingContext2D;
	image: HTMLImageElement;
	

	constructor({ context, image }: ParticleProps) {
		this.x = 0;
		this.y = 0;
		this.xVelocity = 0;
		this.yVelocity = 0;
		this.currentOpacity = 1;
		this.radius = context.canvas.width * 0.4;
		this.rotation = Math.PI;
		this.context = context;
		this.image = image;
	}

	draw() {
		const offset = this.radius / -2;
		this.context.save();
		this.context.translate(this.x, this.y);
		this.context.globalAlpha = this.currentOpacity;
		this.context.rotate(this.rotation);
		this.context.drawImage(this.image, offset, offset, this.radius, this.radius);
		this.context.restore();
	}

	update(canvasWidth: number, canvasHeight: number, growth: number) {
		this.x += this.xVelocity;
		this.y += this.yVelocity;
		this.rotation += Math.random() * Math.PI / 18;
		this.currentOpacity -= 0.004;	
		if (this.y >= canvasHeight / 3 * 2) {
			this.radius = Math.max(this.radius - growth, canvasWidth * 0.2);
		} else {
			this.radius += growth;
		}

		if (this.x + this.radius >= canvasWidth || this.x - this.radius <= 0) {
			this.xVelocity = -this.xVelocity;
		}
	}

	setPosition(x: number, y: number) {
		this.x = x;
		this.y = y;
	}

	setVelocity(x: number, y: number) {
		this.xVelocity = x;
		this.yVelocity = y;
	}
}

interface UseSmokeProps {
	maxParticles: number;
	duration: number;
	imageSrc: string;
}

const useSmoke = ({ maxParticles, duration, imageSrc }: UseSmokeProps) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const particles = useRef<Particle[]>([]);
	const animationFrameId = useRef<number>();
	const imageLoaded = useRef(false);

	const particleImage = new Image();
	particleImage.onload = () => {
		imageLoaded.current = true;
	};
	particleImage.src = imageSrc

	const addParticle = useCallback(
		(context: CanvasRenderingContext2D) => {
			if (particles.current.length < maxParticles) {
				const particle = new Particle({
					context: context,
					image: particleImage,
				});
				particle.setPosition(context.canvas.width / 2, context.canvas.height - particle.radius);
				const xVelocity = context.canvas.width / 2000;
				const yVelocity = context.canvas.height / -300;
				particle.setVelocity(generateRandomNumber(-xVelocity, xVelocity), yVelocity);
				particles.current.push(particle);
			}
		},
		[maxParticles, particleImage]
	);

	const start = useCallback(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		canvas.width = canvas.clientWidth;
		canvas.height = canvas.clientHeight;
		const context = canvas.getContext("2d");
		if (!context) return;

		const startTime = performance.now();

		const growth = canvas.width / 240;  

		const animate = (time: number) => {
			const elapsedTime = time - startTime;

			context.clearRect(0, 0, canvas.width, canvas.height);

			if (elapsedTime <= duration) {
				addParticle(context);
			}

			particles.current.forEach((particle) => {
				particle.draw();
				particle.update(canvas.width, canvas.height, growth);
			});

			particles.current = particles.current.filter((particle) => particle.currentOpacity > 0);

			if (elapsedTime > duration && particles.current.length === 0) {
				cancelAnimationFrame(animationFrameId.current!);
				return;
			}

			animationFrameId.current = requestAnimationFrame(animate);
		};

		animationFrameId.current = requestAnimationFrame(animate);

		return () => {
			if (animationFrameId.current) {
				cancelAnimationFrame(animationFrameId.current);
			}
		};
	}, [addParticle, duration]);

	return { canvasRef, start };
};

export default useSmoke;
