import { useMemo } from "react";
import { MiningSession } from "../types/MiningSession";
import useCountdownTimer from "./useCountdownTimer";

export type MiningState = "PENDING" | "ACTIVE" | "COMPLETED";

type Mining = {
	state: MiningState;
	timeLeft: number;
	progress: number;
	points: number;
};

export const useMining = (miningSession: MiningSession): Mining => {
	const timeLeft = useCountdownTimer({
		deadline: miningSession.endTime,
		precision: 1000,
	});
	const totalMiningTime = useMemo(
		() => miningSession.endTime.getTime() - miningSession.startTime.getTime(),
		[miningSession]
	);
	const timeElapsed = totalMiningTime - timeLeft;
	const progress = totalMiningTime === 0 ? 0 : timeElapsed / totalMiningTime ;
	const unsafePoints = progress * miningSession.points;
	const points = Math.max(0, Math.min(unsafePoints, miningSession.points));
	const state: MiningState = timeLeft > 0 ? "ACTIVE" : (miningSession.isClaimed ? "PENDING" : "COMPLETED");

	return {
		state,
		timeLeft,
		progress,
		points,
	};
};
