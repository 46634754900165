import { useMemo, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { ApiClient } from "../api/client";

import { ReferralCard } from "../components/referralCard";
import Clickable from "../components/Clickable";

import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { BackButton } from "@vkruglikov/react-telegram-web-app";

import { botName } from "../settings";

import type { AppContext } from "../types/AppContext";

import layoutCloud from "../assets/layoutCloud.webp";
import squareCloud from "../assets/squareCloud.webp";
import coinLogo from "../assets/coinLogo.webp";
import linkIcon from "../assets/linkIcon.webp";
import refClaimButton from "../assets/claimButton.webp";
import inviteButton from "../assets/inviteButton.webp";
import copyButton from "../assets/copyButton.webp";

export default function Referral() {
	const { appData, unclaimedReferralPoints, setUnclaimedReferralPoints, setTotalPoints } =
		useOutletContext() as AppContext;
	const webApp = useWebApp();
	const navigate = useNavigate();
	const [earned, setEarned] = useState<number | null>(null);
	const [linkCopied, setLinkCopied] = useState(false);

	const referralUrl = useMemo(
		() => `https://t.me/${botName}/app?startapp=${appData.referralCode}`,
		[appData.referralCode]
	);

	const claimPossible = unclaimedReferralPoints > 0;

	const handleClaimReferralPoints = async () => {
		if (!claimPossible) return;
		const result = await ApiClient.claimReferral(appData.token);
		if (result) {
			setEarned(result.earnedPoints);
			setTimeout(() => setEarned(null), 3000);
			setUnclaimedReferralPoints(0);
			setTotalPoints(result.totalPoints);
		} else {
			return;
		}
	};

	const handleShareReferralLink = () => {
		const encodedUrl = encodeURIComponent(referralUrl);
		const shareUrl = `https://t.me/share/url?url=${encodedUrl}&text=${appData.locale.referralInviteText}`;
		webApp.openTelegramLink(shareUrl);
	};

	const handleCopyLink = async () => {
		if (linkCopied) return;
		try {
			await navigator.clipboard.writeText(referralUrl);
			setLinkCopied(true);
			setTimeout(() => setLinkCopied(false), 3000);
		} catch (err) {
			console.error("Failed to copy: ", err);
		}
	};

	return (
		<>
			<BackButton onClick={() => navigate("/")} />
			<header>
				<img
					src={layoutCloud}
					style={{
						transform: "rotate(180deg)",
						width: "100%",
						filter: "drop-shadow(0px 0px 4px #fff)",
					}}
				/>
				<div className="header-content headrast">
					<h1>{appData.locale.referralsTitle}</h1>
				</div>
			</header>
			<div className="main-content">
				<div className="descrip">
					<div className="desList">
						<Clickable disabled={!claimPossible}>
							<div className="ref-claim">
								<button onClick={handleClaimReferralPoints} className="ref-claim">
									<div className="claim-ref-text">
										<p className="claim-ref-text">{appData.locale.referralClaimButtonText}</p>
									</div>
									<div className="claim-ref-points">
										<p className="claim-ref-points-amount">{unclaimedReferralPoints}</p>
										<img className="claim-ref-coin-logo" src={coinLogo} alt="" />
									</div>
									{earned && (
										<div
											style={{ width: "100%", position: "absolute", right: "0" }}
											className="claim-ref-points earn-animation"
										>
											<p className="claim-ref-points-amount">+ {earned}</p>
											<img className="claim-ref-coin-logo" src={coinLogo} alt="" />
										</div>
									)}
								</button>
								<img
									style={{ width: "100%", filter: claimPossible ? "none" : "saturate(0.1)" }}
									src={refClaimButton}
									alt=""
								/>
							</div>
						</Clickable>
						<div className="desStroke" style={{ marginTop: "2%", gap: 0 }}>
							<Clickable style={{ width: "78%" }}>
								<div onClick={handleShareReferralLink}>
									<img style={{ width: "100%" }} src={inviteButton} alt="" />
									<div
										style={{
											position: "absolute",
											top: "42%",
											transform: "translateY(-50%)",
											paddingLeft: "4%",
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											gap: "4%",
										}}
									>
										<img className="icon-shadow" width={"16%"} src={linkIcon} alt="" />
										<p className="referral-share">{appData.locale.referralLinkShareText}</p>
									</div>
								</div>
							</Clickable>
							<Clickable style={{ width: "22%" }}>
								<img onClick={handleCopyLink} src={copyButton} style={{ width: "100%" }} alt="" />
								{linkCopied && (
									<div
										style={{ position: "absolute", right: "0", top: "10%" }}
										className="earn-animation"
									>
										<p style={{ fontSize: "1em" }} className="claim-ref-points-amount">
											{appData.locale.copiedSuccessfullyText}
										</p>
									</div>
								)}
							</Clickable>
						</div>

						<div className="desStroke">
							<p style={{ textAlign: "center" }} className="referral-system-info">
								{appData.locale.referralSystemInfoText}
							</p>
						</div>
					</div>
					<img style={{ width: "100%", filter: "drop-shadow(0px 0px 4px #fff)" }} src={squareCloud} alt="" />
				</div>

				{appData.referrals
					.sort((a, b) => b.points - a.points)
					.map((user, index) => (
						<ReferralCard key={user.personalData.firstName} index={index} user={user} />
					))}
			</div>
		</>
	);
}
