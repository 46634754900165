import { PropsWithChildren, useState } from "react";

type ClickableProps = PropsWithChildren & {
	disabled?: boolean
    style?: React.CSSProperties
}

const Clickable: React.FC<ClickableProps> = ({ disabled, style, children }) => {
	const [clicked, setClicked] = useState(false);

	return (
		<div
            style={style}
			onMouseDown={() => !disabled && setClicked(true)}
			onMouseUp={() => !disabled && setClicked(false)}
			onMouseLeave={() => !disabled && setClicked(false)}
			onTouchStart={() => !disabled && setClicked(true)}
			onTouchEnd={() => !disabled && setClicked(false)}
			onTouchCancel={() => !disabled && setClicked(false)}
			className={`clickable${clicked ? " clicked" : ""}`}
		>
			{children}
		</div>
	);
};

export default Clickable;