interface MainButtonText {
	PENDING: string;
	ACTIVE: string;
	COMPLETED: string;
}

export interface Locale {
	code: "en" | "ru";
	mainButtonText: MainButtonText;
	tasksTitle: string;
	uncompletedAnimationText: string;
	referralsTitle: string;
	referralClaimButtonText: string;
	referralLinkShareText: string;
	referralInviteText: string;
	copiedSuccessfullyText: string;
	referralSystemInfoText: string;
	noReferralsText: string;
	bybitTaskInfo: {
		title: string;
		errorText: string;
		register: string;
		submit: string;
		pendingText: string;
	};
}

const enLocale: Locale = {
	code: "en",
	mainButtonText: {
		PENDING: "Start Farming",
		ACTIVE: "Burning",
		COMPLETED: "Smoke",
	},
	tasksTitle: "Tasks",
	uncompletedAnimationText: "Task not completed",
	referralsTitle: "Friends",
	referralClaimButtonText: "Claim",
	referralLinkShareText: "Share referral link",
	referralInviteText: "Play WeedCoin with me.\nLet's earn together!\nUse my invite link to join party 🌟",
	referralSystemInfoText: "Get 20% of your friends' claim and 10% from their referrals",
	copiedSuccessfullyText: "Copied",
	noReferralsText: "No friends yet",
	bybitTaskInfo: {
		title: "Explore Bybit!",
		errorText: "Enter your UID",
		register: "Register",
		submit: "Submit",
		pendingText: "Awaiting verification",
	},
};

const ruLocale: Locale = {
	code: "ru",
	mainButtonText: {
		PENDING: "Начать фарминг",
		ACTIVE: "Идёт фарминг",
		COMPLETED: "Взорвать",
	},
	tasksTitle: "Задания",
	uncompletedAnimationText: "Задание не выполнено",
	referralsTitle: "Кенты",
	referralClaimButtonText: "Собрать",
	referralLinkShareText: "Пригласить кента",
	referralInviteText: "Играй со мной в WeedCoin.\nДавай зарабатывать вместе!\nВоспользуйтесь моей ссылкой для приглашения, чтобы присоединиться к веселью 🌟",
	referralSystemInfoText: "Получай 20% от клейма друзей и 10% от их приятелей",
	copiedSuccessfullyText: "Скопировано",
	noReferralsText: "Нет друзей",
	bybitTaskInfo: {
		title: "Откройте мир Bybit!",
		errorText: "Введите UID",
		register: "Регистрация",
		submit: "Подтвердить",
		pendingText: "Ожидание верификации",
	},
};

export const getLocale = (locale: string | null) => {
	switch (locale) {
		case "ru":
		case "ua":
		case "by":
		case "kz":
			return ruLocale;
		default:
			return enLocale;
	}
};
