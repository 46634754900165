import { useNavigate, useOutletContext } from "react-router-dom";
import { AppContext } from "../types/AppContext";
import { ApiClient } from "../api/client";
import { BybitReferralTaskCard, TaskCard } from "../components/taskCard";
import { useMemo, useState } from "react";

import layoutCloud from "../assets/layoutCloud.webp";
import { BybitTaskModal } from "../components/BybitTaskModal";
import { BackButton } from "@vkruglikov/react-telegram-web-app";

export default function Tasks() {
	const navigate = useNavigate();
	const { appData, userTasks, setUserTasks, setTotalPoints, bybitReferralTask, setBybitReferralTask } =
		useOutletContext() as AppContext;
	const [showEarnAnimationForTaskId, setShowEarnAnimationForTaskId] = useState<string | null>(null);
	const [uncompletedAnimationForTaskId, setUncompletedAnimationForTaskId] = useState<string | null>(null);
	const [bybitReferralTaskModalOpen, setBybitReferralTaskModalOpen] = useState<boolean>(false);

	const bybitTaskNotCompleted = !bybitReferralTask || bybitReferralTask.status === "PENDING";

	const handleCheckTask = async (taskId: string) => {
		const ok = await ApiClient.checkTask(appData.token, taskId);
		if (ok) setUserTasks([...userTasks, { id: taskId, isCompleted: false }]);
	};

	const handleVerifyTask = async (taskId: string) => {
		if (showEarnAnimationForTaskId) return;
		if (uncompletedAnimationForTaskId) return;
		const result = await ApiClient.verifyTask(appData.token, taskId);
		// const result = false;
		if (result) {
			setTotalPoints(result.totalPoints);
			setShowEarnAnimationForTaskId(taskId);
			setTimeout(() => {
				setUserTasks(
					[...userTasks].map((task) => (task.id === taskId ? { ...task, isCompleted: true } : task))
				);
				setShowEarnAnimationForTaskId(null);
			}, 3000);
		} else {
			setUncompletedAnimationForTaskId(taskId);
			setTimeout(() => setUncompletedAnimationForTaskId(null), 3000);
		}
	};

	const handleSubmitBybitReferralTask = async (uid: string) => {
		if (bybitReferralTask !== null) return;
		const response = await ApiClient.startBybitTask(appData.token, uid);
		if (!response) return;
		setBybitReferralTaskModalOpen(false);
		setBybitReferralTask(response);
	};

	const handleClaimBybitReferralTask = async () => {
		if (showEarnAnimationForTaskId) return;
		if (uncompletedAnimationForTaskId) return;
		if (!bybitReferralTask || bybitReferralTask.status !== "APPROVED") return;
		const result = await ApiClient.claimBybitTask(appData.token);
		if (!result) return;
		setTotalPoints(result.earn.totalPoints);
		setShowEarnAnimationForTaskId("bybit");
		setTimeout(() => {
			setBybitReferralTask(result.task);
			setShowEarnAnimationForTaskId(null);
		}, 3000);
	};

	const preparedTasks = useMemo(
		() =>
			appData.tasks.map((task) => {
				const userTask = userTasks.find((userTask) => userTask.id === task.id);
				const completed = userTask ? userTask.isCompleted : false;
				console.log("TaskId: ", task.id, "completed: ", completed);
				return {
					...task,
					checked: !!userTask,
					completed: completed,
				};
			}),
		[appData, userTasks]
	);

	return (
		<>
			<BackButton
				onClick={() => {
					if (bybitReferralTaskModalOpen) {
						setBybitReferralTaskModalOpen(false);
					} else {
						navigate("/");
					}
				}}
			/>
			{bybitReferralTaskModalOpen && (
				<BybitTaskModal
					onClose={() => setBybitReferralTaskModalOpen(false)}
					link={appData.bybitReferralTaskData.link}
					task={bybitReferralTask}
					reward={appData.bybitReferralTaskData.reward}
					locale={appData.locale}
					onSubmit={handleSubmitBybitReferralTask}
				/>
			)}

			<header>
				<img
					src={layoutCloud}
					style={{
						transform: "rotate(180deg)",
						width: "100%",
						filter: "drop-shadow(0px 0px 4px #fff)",
					}}
				/>
				<div className="header-content headrast">
					<h1>{appData.locale.tasksTitle}</h1>
				</div>
			</header>
			<div className="main-content">
				{bybitTaskNotCompleted && (
					<BybitReferralTaskCard
						index={0}
						task={bybitReferralTask}
						title={appData.locale.bybitTaskInfo.title}
						reward={appData.bybitReferralTaskData.reward}
						onOpen={() => setBybitReferralTaskModalOpen(true)}
						onClaim={handleClaimBybitReferralTask}
						earnAnimation={showEarnAnimationForTaskId === "bybit"}
					/>
				)}
				{preparedTasks
					.sort((a, b) => Number(b.checked) - Number(a.checked))
					.sort((a, b) => Number(a.completed) - Number(b.completed))
					.map((task, index) => (
						<TaskCard
							key={task.id}
							id={task.id}
							index={index + 1}
							title={task.title[appData.locale.code]}
							reward={task.reward}
							data={task.data}
							isChecked={task.checked}
							isCompleted={task.completed}
							onCheck={handleCheckTask}
							onVerify={() => handleVerifyTask(task.id)}
							earnAnimation={showEarnAnimationForTaskId === task.id}
							uncompletedAnimation={uncompletedAnimationForTaskId === task.id}
							uncompletedAnimationText={appData.locale.uncompletedAnimationText}
						/>
					))}
				{!bybitTaskNotCompleted && (
					<BybitReferralTaskCard
						index={0}
						task={bybitReferralTask}
						title={appData.locale.bybitTaskInfo.title}
						reward={appData.bybitReferralTaskData.reward}
						onOpen={() => setBybitReferralTaskModalOpen(true)}
						onClaim={handleClaimBybitReferralTask}
						earnAnimation={showEarnAnimationForTaskId === "bybit"}
					/>
				)}
			</div>
		</>
	);
}
