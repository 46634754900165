import { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";

import { ApiClient } from "../api/client";
import { getLocale } from "../locales";

import Footer from "../components/Footer";

import { useCanvasAnimation } from "../hooks/useCanvasAnimation";
import useScreenDimensions from "../hooks/useScreenDimensions";

import type { AppData } from "../types/AppData";
import type { MiningSession } from "../types/MiningSession";
import type { UserTask } from "../types/UserTask";
import type { AppContext } from "../types/AppContext";
import type { AnimationParameters } from "../hooks/useCanvasAnimation";

import { useExpand, useInitData } from "@vkruglikov/react-telegram-web-app";
import { BybitReferralTask } from "../types/BybitTask";

import bong from "../assets/bong.webp";
import bongSmoke from "../assets/bongSmoke.webp";
import buds from "../assets/buds.webp";
import bybitPic from "../assets/bybitPic.webp";
import cardCloud1 from "../assets/cardCloud1.webp";
import cardCloud2 from "../assets/cardCloud2.webp";
import cardCloud3 from "../assets/cardCloud3.webp";
import cardCloud4 from "../assets/cardCloud4.webp";
import checkButton from "../assets/checkButton.webp";
import claimButton from "../assets/claimButton.webp";
import coin from "../assets/coin.webp";
import coinLogo from "../assets/coinLogo.webp";
import copyButton from "../assets/copyButton.webp";
import home from "../assets/home.webp";
import hourglassIcon from "../assets/hourglassIcon.webp";
import inviteButton from "../assets/inviteButton.webp";
import layoutCloud from "../assets/layoutCloud.webp";
import linkIcon from "../assets/linkIcon.webp";
import linkOpen from "../assets/linkOpen.webp";
import nCloud from "../assets/nCloud.webp";
import oneRef from "../assets/oneRef.webp";
import rButton from "../assets/rButton.webp";
import refs from "../assets/refs.webp";
import rotateIcon from "../assets/rotateIcon.webp";
import smokeParticle from "../assets/smokeParticle.webp";
import squareCloud from "../assets/squareCloud.webp";
import taskDone from "../assets/taskDone.webp";
import tasks from "../assets/tasks.webp";
import vButton from "../assets/vButton.webp";
import { useImagesPreload } from "../hooks/useImagesPreload";

const assets = [
	bong,
	bongSmoke,
	buds,
	bybitPic,
	cardCloud1,
	cardCloud2,
	cardCloud3,
	cardCloud4,
	checkButton,
	claimButton,
	coin,
	coinLogo,
	copyButton,
	home,
	hourglassIcon,
	inviteButton,
	layoutCloud,
	linkIcon,
	linkOpen,
	nCloud,
	oneRef,
	rButton,
	refs,
	rotateIcon,
	smokeParticle,
	squareCloud,
	taskDone,
	tasks,
	vButton,
];

export default function Root() {
	useImagesPreload(assets);
	const [initDataUnsafe, initDataString] = useInitData();
	const [, expand] = useExpand();
	const [appData, setAppData] = useState<AppData>({} as AppData);

	const [totalPoints, setTotalPoints] = useState(0);
	const [unclaimedReferralPoints, setUnclaimedReferralPoints] = useState(0);

	const [miningSession, setMiningSession] = useState<MiningSession>({} as MiningSession);

	const [userTasks, setUserTasks] = useState<UserTask[]>([]);
	const [bybitReferralTask, setBybitReferralTask] = useState<BybitReferralTask | null>(null);

	const [isLoading, setIsLoading] = useState(true);
	const [showSpinner, setShowSpinner] = useState(true);

	const animationDuration = useMemo(() => 10000, []);
	const { screenWidth, screenHeight } = useScreenDimensions();

	const showRotate = useMemo(() => screenWidth >= screenHeight, [screenWidth, screenHeight]);

	useEffect(() => {
		const init = async () => {
			if (!initDataUnsafe || !initDataString) return;
			expand();
			const data = await ApiClient.init(initDataUnsafe, initDataString);
			setAppData({
				...data,
				locale: getLocale(data.personalData.languageCode),
			});
			setMiningSession(data.miningSession);
			setUserTasks(data.userTasks);
			setBybitReferralTask(data.bybitReferralTask);
			setTotalPoints(data.points);
			setUnclaimedReferralPoints(data.unclaimedReferralPoints);

			setTimeout(() => {
				setIsLoading(false);
				setTimeout(() => {
					setShowSpinner(false);
				}, 400);
			}, 1000);
		};
		init();
	}, [initDataString, initDataUnsafe, expand]);

	const parametricFunction = useCallback((duration: number, timeElapsed: number): AnimationParameters => {
		const progress = timeElapsed / duration;
		const negativeZeroToOne = -4 * Math.pow(progress - 0.5, 2) + 1;
		const positiveZeroToOne = 4 * Math.pow(progress, 2) - 4 * progress + 1;
		return {
			spawnRate: Math.max(positiveZeroToOne, 0.1) * 200,
			angleOffset: progress * Math.PI * 3,
			curvature: 0.5,
			speed: 2,
			opacity: negativeZeroToOne,
		};
	}, []);
	const canvasAnimation = useCanvasAnimation(animationDuration, parametricFunction);

	const handleAnimationStart = () => {
		canvasAnimation.start();
	};

	const context: AppContext = {
		handleAnimationStart,
		appData,
		miningSession,
		setMiningSession,
		totalPoints,
		setTotalPoints,
		unclaimedReferralPoints,
		setUnclaimedReferralPoints,
		userTasks,
		setUserTasks,
		bybitReferralTask,
		setBybitReferralTask,
	};

	return (
		<div className="app">
			{showSpinner && <img src={coinLogo} className="spinner" style={{ opacity: !isLoading ? 0 : 1 }}></img>}
			{!isLoading &&
				(showRotate ? (
					<>
						<img src={rotateIcon} className="spinner"></img>
					</>
				) : (
					<>
						<Outlet context={context} />
						<Footer />
					</>
				))}
			<canvas
				ref={canvasAnimation.ref}
				width={window.innerWidth}
				height={window.innerHeight}
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					width: "300%",
					height: "300%",
					transform: "translate(-50%, -50%)",
					zIndex: -1,
				}}
			/>
			<div className="circleBlur"></div>
		</div>
	);
}
