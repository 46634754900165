import { UserInfo } from "../types/AppData";
import { CardBackground } from "./cardBackground";

import refIcon from "../assets/oneRef.webp";

interface ReferralCardProps {
	index: number;
	user: UserInfo;
}

import coinLogo from "../assets/coinLogo.webp";

const ReferralCard: React.FC<ReferralCardProps> = ({ index, user }) => {
	return (
		<div className="card">
			<div className="card-content">
				<div className="card-left" style={{ width: "60%" }}>
					<div className="card-info" style={{ width: "100%", flex: "none" }}>
						<p
							className="ref-name"
							style={{
								flexShrink: 0,
								textOverflow: "ellipsis",
								overflow: "hidden",
								width: "100%",
								lineHeight: "100%",
							}}
						>
							{`${user.personalData.firstName}${
								user.personalData.lastName ? " " + user.personalData.lastName : ""
							}`}
						</p>

						<div className="refs-count">
							<img className="icon-shadow" height={"80%"} src={refIcon} alt="" />
							<p className="ref-refs">{user.referralsCount}</p>
						</div>
					</div>
				</div>
				<div className="card-right">
					<div className="ref-total-points">
						{user.points.toFixed(2)}
						<img
							src={coinLogo}
							alt="logo"
							style={{
								height: "100%",
								filter: "drop-shadow(var(--quaternary-color) -0.5px -0.5px 0.01em) drop-shadow(var(--quaternary-shadow-color) 0.5px 0.5px 0.01em)",
							}}
						/>
					</div>
				</div>
			</div>
			<CardBackground index={index} />
		</div>
	);
};

export { ReferralCard };
