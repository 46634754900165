import { useState, useEffect, useRef, useCallback } from 'react';

interface CountDownTimerProps {
    onTimeUp?: () => void;
    deadline: Date;
    precision?: number;
}

function useCountdownTimer({ onTimeUp, deadline, precision = 1000 }: CountDownTimerProps): number {
    const [timeLeft, setTimeLeft] = useState<number>(Math.max(deadline.getTime() - Date.now(), 0));
    const intervalId = useRef<number | null>(null);

    const updateTimeLeft = useCallback(() => {
        const now = Date.now();
        const remaining = deadline.getTime() - now;
        if (remaining <= 0) {
            if (intervalId.current !== null) clearInterval(intervalId.current);
            intervalId.current = null;
            setTimeLeft(0);
            onTimeUp?.();
        } else {
            // Adjust the remaining time to the closest precision step
            const adjustedRemaining = remaining - (remaining % precision);
            setTimeLeft(adjustedRemaining);
        }
    }, [deadline, onTimeUp, precision]);

    useEffect(() => {
        // Clear any existing interval before creating a new one
        if (intervalId.current !== null) {
            clearInterval(intervalId.current);
        }
        
        updateTimeLeft(); // Update immediately to ensure correct initial state
        intervalId.current = window.setInterval(updateTimeLeft, precision);

        return () => {
            if (intervalId.current !== null) {
                clearInterval(intervalId.current);
            }
        };
    }, [updateTimeLeft, precision, deadline]); // Include deadline in the dependencies array

    return timeLeft;
}

export default useCountdownTimer;