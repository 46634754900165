import React, { useRef, useEffect } from "react";

interface ClickableImageProps {
	onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	style?: React.CSSProperties;
	src: string;
}

const ClickableImage: React.FC<ClickableImageProps> = ({ onClick, style, src }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const imgRef = useRef<HTMLImageElement>(null);

	useEffect(() => {
		const img = imgRef.current;
		const canvas = canvasRef.current;
		const container = containerRef.current;
		const ctx = canvas?.getContext("2d");

		if (img && canvas && ctx && container) {
			img.onload = () => {
				const { width, height } = container.getBoundingClientRect();
				canvas.width = width;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, width, height);
			};
		}
	}, [src]);

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const canvas = canvasRef.current;
		const ctx = canvas?.getContext("2d");

		if (canvas && ctx) {
			const rect = canvas.getBoundingClientRect();
			const x = e.clientX - rect.left;
			const y = e.clientY - rect.top;
			const pixel = ctx.getImageData(x, y, 1, 1).data;
			console.log(pixel);
			// Check if the alpha channel is 0 (transparent)
			if (pixel[3] < 2) {
				onClick(e);
			}
		}

		e.stopPropagation();
	};

	return (
		<div ref={containerRef} style={style} onClick={handleClick}>
			<canvas ref={canvasRef} style={{ position: "absolute", width: "100%", height: "100%", display: "none" }} />
			<img
				ref={imgRef}
				src={src}
				alt="Clickable"
				style={{ position: "absolute", width: "100%", height: "100%", display: "block" }}
			/>
		</div>
	);
};

export default ClickableImage;
