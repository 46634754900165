import { useState } from "react";

import { ApiClient } from "../api/client";

import { useOutletContext } from "react-router-dom";
import { useBongAnimation } from "../hooks/useBongAnimation";
import { useMining } from "../hooks/useMining";
import useSmokeAnimation from "../hooks/useSmokeAnimation";

import type { AppContext } from "../types/AppContext";

import layoutCloud from "../assets/layoutCloud.webp";
import coinLogo from "../assets/coinLogo.webp";
import coin from "../assets/coin.webp";
import bong from "../assets/bong.webp";
import smoke from "../assets/bongSmoke.webp";
import buds from "../assets/buds.webp";
import smokeParticle from "../assets/smokeParticle.webp";

export default function Home() {
	const { handleAnimationStart, appData, miningSession, setMiningSession, totalPoints, setTotalPoints } =
		useOutletContext() as AppContext;
	const smokeAnimation = useSmokeAnimation({ maxParticles: 246, duration: 10000, imageSrc: smokeParticle });
	const bongAnimation = useBongAnimation({ duration: 10000 });
	const mining = useMining(miningSession);
	const [claimAnimation, setClaimAnimation] = useState(false);

	const formatTime = (timeMs: number) => {
		const time = timeMs / 1000;
		const hours = Math.floor(time / 3600);
		const minutes = Math.floor((time % 3600) / 60); // Fix to correctly calculate minutes
		return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
	};

	const handleMainButtonClick = async () => {
		if (claimAnimation) return;
		if (mining.state === "COMPLETED") {
			const result = await ApiClient.claimMined(appData.token);
			if (!result) return;
			setClaimAnimation(true);
			handleClaimAnimation();
			setTimeout(() => {
				setMiningSession(result.session);
			}, 400);

			setTotalPoints(result.claimResult.totalPoints);
			setTimeout(() => setClaimAnimation(false), 10000);
		} else if (mining.state === "PENDING") {
			const result = await ApiClient.startMining(appData.token);
			if (!result) return;
			setMiningSession(result);
		} else {
			return;
		}
	};

	const handleClaimAnimation = () => {
		handleAnimationStart();
		bongAnimation.start();
		smokeAnimation.start();
	};

	return (
		<>
			<header>
				<img
					src={layoutCloud}
					style={{
						transform: "rotate(180deg)",
						width: "100%",
						filter: "drop-shadow(0px 0px 4px #fff)",
					}}
				/>
				<div className="header-content">
					<img
						src={coinLogo}
						alt="logo"
						style={{
							width: "12%",
							filter: "drop-shadow(0px 0px 0.04em #ffacac) drop-shadow(1px 1px 0.01em #00000080)",
						}}
					/>
					<p className="coinCount">{totalPoints.toFixed(2)}</p>
				</div>
			</header>

			<div className="main-content reversed">
				<img
					src={coin}
					alt=""
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						height: "30%",
						transform: "translate(-50%, -50%)",
					}}
				/>
				<div style={{ width: "80%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
					<div
						style={{
							bottom: "4%",
							left: "-20%",
							width: "40%",
							height: "74vh",
							position: "absolute",
						}}
					>
						<canvas ref={smokeAnimation.canvasRef} id="smoke-animation"></canvas>
					</div>
					<button
						onClick={handleMainButtonClick}
						style={{
							width: "100%",
							background: "none",
							border: "none",
							alignItems: "center",
							transition: "background 1.7s ease",
						}}
					>
						<div
							style={{
								width: "72.4%",
								position: "absolute",
								top: "44%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								paddingLeft: "4%",
								paddingRight: "4%",
								zIndex: 4,
								fontWeight: 600,
							}}
						>
							{mining.state === "COMPLETED" || mining.state === "PENDING" ? (
								<p className={`button-text${claimAnimation ? " transparent" : ""}`}>
									{appData.locale.mainButtonText[mining.state]}
								</p>
							) : (
								<p
									className={`timeLast${
										claimAnimation || ["COMPLETED", "PENDING"].includes(mining.state)
											? " transparent"
											: ""
									}`}
								>
									{formatTime(mining.timeLeft)}
								</p>
							)}

							<p
								className={`button-text mining-points${
									claimAnimation || ["PENDING"].includes(mining.state) ? " transparent" : ""
								}`}
							>
								{mining.points.toFixed(2)}
							</p>
						</div>
						<div style={{ width: "100%" }}>
							<img
								style={{
									width: "100%",
									height: "100%",
									objectFit: "contain",
									position: "relative",
									top: 0,
									left: 0,
									zIndex: 3,
								}}
								src={bong}
								alt=""
							/>
							<img
								src={buds}
								alt=""
								style={{
									opacity: mining.state !== "ACTIVE" || claimAnimation ? 0 : 1,
									transition: "opacity 0.5s ease-in-out",
									width: "6%",
									position: "absolute",
									right: "32%",
									top: -2,
								}}
							/>
							<img
								ref={bongAnimation.ref}
								className="bongSmoke"
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									zIndex: 1,
									opacity: mining.state === "PENDING" && !claimAnimation ? 0 : 1,
									width: "100%",
									objectFit: "cover",
									maskImage: `linear-gradient(to left, rgba(0,0,0,1) ${
										mining.progress * 100
									}%, rgba(0,0,0,0) ${mining.progress * 100 + 20}%)`,
								}}
								src={smoke}
								alt=""
							/>
						</div>
					</button>
				</div>
			</div>
		</>
	);
}
