import { InitData, InitDataUnsafe } from "@vkruglikov/react-telegram-web-app";
import axios, { AxiosRequestConfig, AxiosResponse, isAxiosError } from "axios";
import { ZodError, ZodSchema, z } from "zod";
import {
	BybitTaskCompleteSchema,
	BybitReferralTaskSchema,
	InitSchema,
	MiningResultSchema,
	MiningSessionSchema,
	PointsEarnSchema,
} from "./schemas";

const axiosInstance = axios.create({
	baseURL: import.meta.env.BACKEND_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

export class ApiClient {
	private static async makeRequest(config: AxiosRequestConfig): Promise<AxiosResponse> {
		try {
			const response = await axiosInstance.request(config);
			return response;
		} catch (error) {
			if (isAxiosError(error)) {
				console.warn(error);
				if (error.response?.status === 502) {
					if (error.response.data) {
						throw new Response(error.response.data, {
							status: error.response.status,
						});
					}
				} else {
					throw error;
				}
			}
			throw error;
		}
	}

	private static parseJson<T extends ZodSchema>(schema: T, data: unknown): z.infer<T> {
		try {
			return schema.parse(data);
		} catch (error) {
			if (error instanceof ZodError) {
				console.error(error.message);
				console.error(error.issues);
			}
			throw error;
		}
	}

	public static async init(dataUnsafe: InitDataUnsafe, dataString: InitData) {
		const response = await this.makeRequest({
			url: "/init",
			method: "POST",
			data: {
				data: dataUnsafe,
				string: dataString,
			},
		});
		return this.parseJson(InitSchema, response.data);
	}

	public static async startMining(token: string) {
		try {
			const response = await this.makeRequest({
				url: "/mining/start",
				method: "POST",
				params: {
					token,
				},
			});
			return this.parseJson(MiningSessionSchema, response.data);
		} catch (error) {
			if (isAxiosError(error) && error.response?.status === 400) {
				return null;
			} else {
				throw error;
			}
		}
	}

	public static async claimMined(token: string) {
		try {
			const response = await this.makeRequest({
				url: "/mining/claim",
				method: "POST",
				params: {
					token,
				},
			});
			return this.parseJson(MiningResultSchema, response.data);
		} catch (error) {
			if (isAxiosError(error) && error.response?.status === 400) {
				return null;
			} else {
				throw error;
			}
		}
	}

	public static async claimReferral(token: string) {
		try {
			const response = await this.makeRequest({
				url: "/referral/claim",
				method: "POST",
				params: {
					token,
				},
			});
			return this.parseJson(PointsEarnSchema, response.data);
		} catch (error) {
			if (isAxiosError(error) && error.response?.status === 400) {
				return null;
			} else {
				throw error;
			}
		}
	}

	public static async checkTask(token: string, taskId: string) {
		try {
			await this.makeRequest({
				url: "/task/check",
				method: "POST",
				params: {
					token,
					task_id: taskId,
				},
			});
			return true;
		} catch (error) {
			return null;
		}
	}

	public static async verifyTask(token: string, taskId: string) {
		try {
			const response = await this.makeRequest({
				url: "/task/verify",
				method: "POST",
				params: {
					token,
					task_id: taskId,
				},
			});
			return this.parseJson(PointsEarnSchema, response.data);
		} catch (error) {
			if (isAxiosError(error) && error.response?.status === 400) {
				return null;
			} else {
				throw error;
			}
		}
	}

	public static async startBybitTask(token: string, bybitId: string) {
		try {
			const response = await this.makeRequest({
				url: "/bybit/start",
				method: "POST",
				params: {
					token,
					bybit_id: bybitId,
				},
			});
			return this.parseJson(BybitReferralTaskSchema, response.data);
		} catch (error) {
			if (isAxiosError(error) && error.response?.status === 400) {
				return null;
			} else {
				throw error;
			}
		}
	}

	public static async claimBybitTask(token: string) {
		try {
			const response = await this.makeRequest({
				url: "/bybit/claim",
				method: "POST",
				params: {
					token,
				},
			});
			return this.parseJson(BybitTaskCompleteSchema, response.data);
		} catch (error) {
			if (isAxiosError(error) && error.response?.status === 400) {
				return null;
			} else {
				throw error;
			}
		}
	}
}
