import ReactDOM from "react-dom/client";
import "./App.css";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/home";
import Referral from "./routes/referral";
import Root from "./routes/root";
import Tasks from "./routes/tasks";

import * as Sentry from "@sentry/react";
import axios from "axios";

Sentry.init({
	dsn: "https://f84fef63943d11cf5b0c1a26ceb27792@o4507403858935808.ingest.de.sentry.io/4507403862933584",
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	beforeSend(event, hint) {
		const error = hint.originalException;
		if (axios.isAxiosError(error)) {
			event.extra = {
				...event.extra,
				requestData: {
					url: error.config?.url,
					method: error.config?.method,
					data: error.config?.data,
					headers: error.config?.headers,
				},
				responseData: {
					data: error.response?.data,
					status: error.response?.status,
					headers: error.response?.headers,
				},
			};
		}
		return event;
	},
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: "tasks",
				element: <Tasks />,
			},
			{
				path: "referral",
				element: <Referral />,
			},
		],
	},
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
