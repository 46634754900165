import { useEffect } from "react";

const useImagesPreload = (imageUrls: string[]) => {
	useEffect(() => {
		imageUrls.forEach((url) => {
			const img = new Image();
			img.src = url;
		});
	}, [imageUrls]);
};

export { useImagesPreload };
