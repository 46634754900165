import { z } from "zod";

const DecimalStringToNumberSchema = z.number().or(z.string()).pipe(z.coerce.number());
const ISOToDateSchema = z.string().datetime().pipe(z.coerce.date());
const BybitTaskStatusSchema = z.enum(["PENDING", "APPROVED", "CLAIMED"]);

export const UserTaskSchema = z.object({
	id: z.string(),
	isCompleted: z.boolean(),
});

export const PersonalDataSchema = z.object({
	firstName: z.string(),
	lastName: z.string().nullable(),
	languageCode: z.string().nullable(),
	username: z.string().nullable(),
	photoUrl: z.string().nullable(),
});

export const UserInfoSchema = z.object({
	personalData: PersonalDataSchema,
	points: DecimalStringToNumberSchema,
	referralsCount: z.number(),
});

export const PointsEarnSchema = z.object({
	earnedPoints: DecimalStringToNumberSchema,
	totalPoints: DecimalStringToNumberSchema,
});

export const MiningSessionSchema = z.object({
	startTime: ISOToDateSchema,
	endTime: ISOToDateSchema,
	points: DecimalStringToNumberSchema,
	isClaimed: z.boolean(),
});

export const MiningResultSchema = z.object({
	session: MiningSessionSchema,
	claimResult: PointsEarnSchema,
});

const LocaleTitleSchema = z.object({
	en: z.string(),
	ru: z.string(),
});

const BaseTaskDataSchema = z.object({
	type: z.literal("TASK"),
});

const TelegramChannelSubscriptionTaskDataSchema = BaseTaskDataSchema.extend({
	type: z.literal("TELEGRAM_CHANNEL_SUBSCRIPTION"),
	inviteLink: z.string().nullable(),
});

const DelayedRelatedTaskDataSchema = BaseTaskDataSchema.extend({
	type: z.literal("DELAYED_RELATED"),
	period: z.number(),
});

export const TaskDataSchema = z.discriminatedUnion("type", [
	BaseTaskDataSchema,
	TelegramChannelSubscriptionTaskDataSchema,
	DelayedRelatedTaskDataSchema,
]);

export const TaskSchema = z.object({
	id: z.string(),
	title: LocaleTitleSchema,
	reward: DecimalStringToNumberSchema,
	data: TaskDataSchema,
});

export const BybitReferralTaskSchema = z.object({
	status: BybitTaskStatusSchema,
});

export const BybitReferralTaskDataSchema = z.object({
	link: z.string(),
	reward: DecimalStringToNumberSchema,
});

export const BybitTaskCompleteSchema = z.object({
	task: BybitReferralTaskSchema,
	earn: PointsEarnSchema,
});

export const InitSchema = z.object({
	token: z.string(),
	points: DecimalStringToNumberSchema,
	personalData: PersonalDataSchema,
	referralCode: z.string(),
	referrals: z.array(UserInfoSchema),
	unclaimedReferralPoints: DecimalStringToNumberSchema,
	userTasks: z.array(UserTaskSchema),
	miningSession: MiningSessionSchema,
	tasks: z.array(TaskSchema),
	bybitReferralTaskData: BybitReferralTaskDataSchema,
	bybitReferralTask: BybitReferralTaskSchema.nullable(),
});
