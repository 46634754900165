import { NavLink } from "react-router-dom";

import layoutCloud from "../assets/layoutCloud.webp";
import homeIcon from "../assets/home.webp";
import tasksIcon from "../assets/tasks.webp";
import referralsIcon from "../assets/refs.webp";

import Clickable from "./Clickable";

export default function Footer() {
	return (
		<footer>
			<div className="menu">
				<NavLink to="/">
					{({ isActive }) => (
						<Clickable>
							<img
								className={`icon-shadow menuIcon${isActive ? " active" : ""}`}
								src={homeIcon}
								alt="Home"
							/>
						</Clickable>
					)}
				</NavLink>
				<NavLink to="/tasks">
					{({ isActive }) => (
						<Clickable>
							<img
								className={`icon-shadow menuIcon${isActive ? " active" : ""}`}
								src={tasksIcon}
								alt="Tasks"
							/>
						</Clickable>
					)}
				</NavLink>

				<NavLink to="/referral">
					{({ isActive }) => (
						<Clickable>
							<img
								className={`icon-shadow menuIcon${isActive ? " active" : ""}`}
								src={referralsIcon}
								alt="Referrals"
							/>
						</Clickable>
					)}
				</NavLink>
			</div>
			<img style={{ width: "100%", filter: "drop-shadow(0px 0px 4px #fff)" }} src={layoutCloud} alt="" />
		</footer>
	);
}
