import modalCloud from "../assets/nCloud.webp";
import bybitLogo from "../assets/bybitPic.webp";
import registrationButtonCloud from "../assets/rButton.webp";
import verifyButtonCloud from "../assets/vButton.webp";
import Clickable from "../components/Clickable";
import coinLogo from "../assets/coinLogo.webp";
import hourglassIcon from "../assets/hourglassIcon.webp";

import { Locale } from "../locales";
import { useState } from "react";
import { BybitReferralTask } from "../types/BybitTask";
import ClickableImage from "./ClickableImage";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";

interface BybitTaskModalProps {
	onClose: () => void;
	task: BybitReferralTask | null;
	link: string;
	reward: number;
	locale: Locale;
	onSubmit: (uid: string) => void;
}

const BybitTaskModal: React.FC<BybitTaskModalProps> = ({ onClose, task, link, reward, locale, onSubmit }) => {
	const webApp = useWebApp();
	const [input, setInput] = useState("");
	const [error, setError] = useState<boolean>(false);
	const [codeCopied, setCodeCopied] = useState<boolean>(false);

	const code = "APE7B5";

	const handleCopyCode = async () => {
		if (codeCopied) return;
		try {
			await navigator.clipboard.writeText(code);
			setCodeCopied(true);
			setTimeout(() => setCodeCopied(false), 3000);
		} catch (err) {
			console.error("Failed to copy: ", err);
		}
	};

	const handleSubmit = () => {
		if (input === "") {
			setError(true);
			return;
		}
		onSubmit(input);
	};

	return (
		<div
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				height: "100lvh",
				width: "100lvw",
				zIndex: 100,
				backgroundColor: "rgba(0, 0, 0, 0.5)",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
			onClick={onClose}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				style={{
					position: "absolute",
					top: "28%",
					left: "50%",
					transform: "translateX(-50%)",
					height: "66%",
					width: "82%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					textAlign: "center",
					justifyContent: "space-around",
					zIndex: 101,
				}}
			>
				<p className="coinCount">{locale.bybitTaskInfo.title}</p>

				{task !== null ? (
					<div
						style={{
							height: "100%",
							width: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							paddingLeft: "8%",
							paddingRight: "8%",
						}}
					>
						<img className="icon-shadow" height={"20%"} src={hourglassIcon} alt="" />
						<p>{locale.bybitTaskInfo.pendingText}</p>
					</div>
				) : (
					<>
						{locale.code === "en" ? (
							<p style={{ fontSize: "2.6vh" }}>
								Register <span style={{ color: "var(--quaternary-color)" }}>NEW</span> account on{" "}
								<span style={{ color: "#000" }}>ByB</span>
								<span style={{ color: "orange" }}>i</span>
								<span style={{ color: "#000" }}>t</span>, using button below or by specifying the code{" "}
								<span
									onClick={handleCopyCode}
									style={{ position: "relative", color: "var(--quaternary-color)" }}
								>
									{code}
									{codeCopied && (
										<div
											style={{
												position: "absolute",
												left: 0,
												top: 0,
												zIndex: 110,
											}}
											className="earn-animation"
										>
											<p style={{ fontSize: "1em" }} className="claim-ref-points-amount">
												{locale.copiedSuccessfullyText}
											</p>
										</div>
									)}
								</span>{" "}
								during registration. After registration and verification completion(
								<span style={{ color: "var(--quaternary-color)" }}>KYC</span>), enter your{" "}
								<span style={{ color: "var(--quaternary-color)" }}>UID</span> in corresponding field for
								task completion and earning{" "}
								<span style={{ color: "var(--quaternary-color)" }}>reward</span>. You can find{" "}
								<span style={{ color: "var(--quaternary-color)" }}>UID</span> in your{" "}
								<span style={{ color: "#000" }}>ByB</span>
								<span style={{ color: "orange" }}>i</span>
								<span style={{ color: "#000" }}>t</span> profile .
							</p>
						) : (
							<p style={{ fontSize: "2.6vh" }}>
								Зарегистрируйте <span style={{ color: "var(--quaternary-color)" }}>НОВЫЙ</span> аккаунт
								на бирже <span style={{ color: "#000" }}>ByB</span>
								<span style={{ color: "orange" }}>i</span>
								<span style={{ color: "#000" }}>t</span>, используя кнопку ниже, либо указав код{" "}
								<span
									onClick={handleCopyCode}
									style={{ position: "relative", color: "var(--quaternary-color)" }}
								>
									{code}
									{codeCopied && (
										<div
											style={{ position: "absolute", left: 0, top: 0, zIndex: 110 }}
											className="earn-animation"
										>
											<p style={{ fontSize: "1em" }} className="claim-ref-points-amount">
												{locale.copiedSuccessfullyText}
											</p>
										</div>
									)}
								</span>{" "}
								при регистрации. После регистрации и прохождения верификации(
								<span style={{ color: "var(--quaternary-color)" }}>KYC</span>) введите ваш{" "}
								<span style={{ color: "var(--quaternary-color)" }}>UID</span> в соответствующее поле,
								для проверки выполнения задания и получения{" "}
								<span style={{ color: "var(--quaternary-color)" }}>награды</span>. Вы можете найти{" "}
								<span style={{ color: "var(--quaternary-color)" }}>UID</span> в профиле вашего аккаунта{" "}
								<span style={{ color: "#000" }}>ByB</span>
								<span style={{ color: "orange" }}>i</span>
								<span style={{ color: "#000" }}>t</span>.
							</p>
						)}

						<div style={{ justifyContent: "center" }} className={`task-reward`}>
							+ {reward}
							<img
								src={coinLogo}
								alt="logo"
								style={{
									width: "10%",
									filter: "drop-shadow(var(--quaternary-color) -0.5px -0.5px 0.01em) drop-shadow(var(--quaternary-shadow-color) 0.5px 0.5px 0.01em)",
								}}
							/>
						</div>

						<Clickable style={{ width: "60%", height: "12%" }}>
							<button
								onClick={() => webApp.openLink(link)}
								style={{
									width: "100%",
									height: "100%",
									background: "none",
									border: "none",
									textAlign: "center",
								}}
							>
								<img
									className="icon-shadow"
									style={{
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
										width: "100%",
										height: "100%",
										objectFit: "fill",
									}}
									src={registrationButtonCloud}
									alt=""
								/>
								<p
									style={{
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
										color: "white",
										zIndex: 105,
										fontSize: "1.2em",
									}}
								>
									{locale.bybitTaskInfo.register}
								</p>
							</button>
						</Clickable>

						<input
							value={input}
							onChange={(e) => {
								setError(false);
								setInput(e.target.value);
							}}
							style={{
								width: "90%",
								height: "10%",
								border: "none",
								boxShadow: error
									? "inset 0px 0.1em 0.2em rgba(100, 0, 0, 0.6)"
									: "inset 0px 0.1em 0.2em rgba(100, 0, 0, 0.6)",
								backgroundColor: "rgba(255, 67, 67, 0.4)",
								color: "white",
								textAlign: "center",
								boxSizing: "border-box",
								borderRadius: "1em",
							}}
							placeholder="UID"
						/>
						{error && (
							<center>
								<p style={{ fontSize: "0.5em", color: "red" }}>{locale.bybitTaskInfo.errorText}</p>
							</center>
						)}

						<Clickable style={{ width: "60%", height: "12%" }}>
							<button
								style={{
									width: "100%",
									height: "100%",
									background: "none",
									border: "none",
									textAlign: "center",
								}}
								onClick={handleSubmit}
							>
								<img
									className="icon-shadow"
									style={{
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
										width: "100%",
										height: "100%",
										objectFit: "fill",
									}}
									src={verifyButtonCloud}
									alt=""
								/>
								<p
									style={{
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
										color: "var(--quaternary-color)",
										zIndex: 105,
										fontSize: "1.2em",
									}}
								>
									{locale.bybitTaskInfo.submit}
								</p>
							</button>
						</Clickable>
					</>
				)}
			</div>
			<img
				onClick={(e) => e.stopPropagation()}
				src={bybitLogo}
				style={{
					position: "absolute",
					top: "11%",
					left: "50%",
					transform: "translateX(-50%)",
					height: "16%",
					zIndex: 101,
					filter: "drop-shadow(0px 0px 0.1em #000)",
				}}
			/>

			<ClickableImage
				style={{
					height: "92%",
					width: "92%",
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				}}
				src={modalCloud}
				onClick={onClose}
			/>
		</div>
	);
};

export { BybitTaskModal };
